/* --- Universal styles --- */ 

:root {
  font-size: 16px;
}


html {
  height: 100%;
}



.Default-Styling {
  background: -webkit-linear-gradient(white, white 0%, black 0%, #121212, black  calc(var(--default-height)*var(--top-proportion-of-height-BG-end)), white  calc(var(--default-height)*var(--top-proportion-of-height-BG-end)), white);
  margin: 8px;

  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: whitesmoke;

  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  --default-height: 97vh;
  --default-width: 90vw;
  --top-proportion-of-height: .66;
  --bottom-proportion-of-height: .29;
  --top-proportion-of-height-BG-end: .69;

  --top-proportion-of-height-phone: .53;
  --bottom-proportion-of-height-phone: .29;
  --top-proportion-of-height-BG-end-phone: .56;


  --secondary_color:#FFBF49;
  --tertiary_color:#FD4855;

}



.Hover-Color:hover{
  color: blue;
}





.Overall-Grid-Parent{
  display: grid;
  grid-template-rows: calc(var(--default-height)*var(--top-proportion-of-height)) calc(var(--default-height)*var(--bottom-proportion-of-height));
  align-content: start;
}

.Overall-Grid-Child{
}

.Mobile-View{
  display: none;
}



/* ----- Top Section, for when Main is selected ----- */ 

.Main-Text-Emph{
}



.Main-Text-Deemph{
  color:#A3A3A3;

}


/* Leaving gap in main-flex-parent even though it doesnt work in all browers for flexboxes,
since the effect is nice but minimal, so it isnt a significant risk for cross-browser design. */

.Main-Flex-Parent{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-basis: 100%;
  justify-items: stretch;
  height: calc(var(--default-height)*var(--top-proportion-of-height));
  gap: .3125rem;
  font-size:2.5rem;
}


.Line-1{
  margin-left: 6.25rem;
}

.Line-2{
  margin-left: 12.5rem;

}

.Line-3{
  margin-left: 18.75rem;

}


.Inline-L{
  display: inline;
  position: absolute;
  margin-left: -.5625rem;
  padding-top: 1.0625rem;
}



/* ----- Top Section, for when About is selected  ----- */ 


.About-Flex-Parent{
  display: flex;
  flex-basis: 100%;
  
  height: calc(var(--default-height)*var(--top-proportion-of-height));
  font-size:1.125rem;
  
  justify-content: center;
  align-items: center;
}


.About-Text-Boundaries{
  margin: 1.25rem 1.25rem 1.25rem 2.25rem;
  
  max-width: 39rem;
}

    /* Currently using About-Text-Boundaries and About-Flex-Parent for both About component and Portfolio component.
    because it is convenient for similar needs and the media queries attached to those.
    Will give it a more generic name if reused for a 3rd time. */

p{
  line-height: 2rem;
}

.Large-View{
  line-height: 2rem;
}




/* ----- Top Section, for when Sites is selected  ----- */ 


.Portfolio-Text{
  text-align: center;  
}


.Portfolio-Site-Title:link, .Portfolio-Site-Title:visited{
  color: rgb(19, 108, 241);
  text-decoration: underline;
}


.Portfolio-Site-Title:visited{
  color: purple;
}



.Portfolio-Site-Title:hover{
  color: red;
}





/* ----- Lower Half, mainly Navbar ----- */ 

/* ----- Navbar ----- */ 




.Nav-Selected{
  color: var(--tertiary_color);
  
}


.Nav-Unselected{
  color: #6A6A6A;
}


/*
Adjusting the color of links. Reasoning:
Few visitable links and they're almost all on the navbar,
so their clickability will be obvious, and color differences for clicked links
would have negligible function gain vs significant impact on the attention flow 
and color design of the page. 
*/

a:visited, a:link{
  color: #6A6A6A;
  text-decoration: none;
}



/* The padding/margin setup is to set up a replacement for the fact that the "gap"
property doesnt work in flexbox on many browsers.
The effect is mostly important when there's 2 rows of navbar icons.
*/

.As-Text {
  background:none;
  border:none;
  cursor: pointer;   
  padding: 3rem 0rem 0rem 0rem;     
  margin: -3rem 0rem 0rem 0rem; 
}


/* .Nav-Unselected > a is here to make clickable area for navbar links bigger.
*/

.Nav-Unselected > a {     
  display: inline-block;     
  padding: 3rem 0rem 0rem 0rem;     
  margin: -3rem 0rem 0rem 0rem; 
}

/* ...Nontext styles */ 


.Nav-Background{
}



.Nav-Flex-Parent{
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  height: calc(var(--default-height)*var(--bottom-proportion-of-height));
  padding-right:1.5rem;
  padding-top: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  margin-left: -2rem;
}

/*
For now, Nav-Flex-Child's margins are not merged into as-text because of
differences between about/man vs resume/github for how exactly the styling plays out,
due to about/main being buttons, which have some idiosyncrasies.
Will be changed later.
*/

.Nav-Flex-Child{
  margin: 0 0 0 2rem;
}


/* SVG */ 



.Svg-Icon {
  display: flex; 
  width: 2.1rem;
  height: 2rem;
}


.Svg-Icon path,
.Svg-Icon polygon,
.Svg-Icon rect {
  fill:currentColor;
}

.Svg-Icon circle {
  stroke: currentColor;
  stroke-width: 1;
}


.Svg-Icon-Margin-Norm{
  display: block;
  margin: auto;
  margin-bottom: 10%;
}

.Svg-Icon-Margin-Alternate{
  display: block;
  margin: auto;
  margin-bottom: 5%;

  
}


.Svg-Icon-Email-Button {
  display: inline-block;
  position: relative;
  float:center;
  color: var(--secondary_color);
  width: 3.5rem;
  height: 3.5rem;
  left: 0.5rem;
}

.Svg-Icon-Email-Button-2 {
  display: inline-block;
  position: relative;
  float:center;
  color: var(--secondary_color);
  width: 3.5rem;
  height: 3.5rem;
  bottom: 0.8rem;
  left: 0.5rem;
}


/*
Below styling is here to make the email button not take up space(to make certain other things easier) while also still
making the email button's spacing relative in the above two styles.
It also makes the "Let's work together" text clickable(triggering the email button), for now.
*/

.About-Email-Line{
  max-width: 16rem;
  margin-top: 1.5rem;
}

.Line-3 > a{
  position: absolute;
  padding-left: 28rem;
  margin-left: -28rem;
}

.About-Email-Line > a{
  position: absolute;
  padding-left: 12rem;
  margin-left: -12rem;
}


.Svg-Icon:hover .Nav-Unselected,
.Hover-Color:hover .Svg-Icon-Email-Button,
.About-Email-Line:hover  .Svg-Icon-Email-Button-2{
  color: blue;
}


/* Responsive Adjustments */ 

@media (min-width:1400px) and (min-height: 850px){


  .About-Flex-Parent{
    font-size: 1.375rem;
}

  .About-Text-Boundaries{
    max-width: 48rem;
  }

 p{
  line-height: 2.5rem;
}

.Large-View{
  line-height: 3rem;
}

}




@media (min-width:800px) and (min-height: 600px){

  .Portfolio-Parent > .About-Text-Boundaries{
    margin-top: 4.5rem;
  }


  .Portfolio-Text > p{
    margin-bottom: 3rem;
  }

}


@media (max-width:890px){


  .Main-Flex-Parent{
    font-size:2rem;
  }

  .Line-1{
    margin-left: 4.6875rem;
  }
  .Line-2{
    margin-left: 9.375rem;
  }
  .Line-3{
    margin-left: 14.0625rem;
  }
  .Inline-L{
    max-width: 1.75rem;
    max-height: 1.75rem;
  }
  
.Svg-Icon-Email-Button{
  bottom: 0.2rem;
}

.Svg-Icon-Email-Button-2{
  bottom: 1rem;
}

  

}


@media (min-width:760px) and (max-height: 530px){

  .About-Flex-Parent{
    font-size:1rem;
  }

}


@media (max-width:760px){


  .Main-Flex-Parent{
    font-size:1.4rem;
  }

  .Line-1{
    margin-left: 2.475rem;
  }
  .Line-2{
    margin-left: 3.75rem;
  }
  .Line-3{
    margin-left: 4.9875rem;
  }
  .Inline-L{
    margin-top: -.3125rem;
    margin-left: -.4375rem;
    max-width: 1.1875rem;
    max-height: 1.1875rem;
  }
  .Svg-Icon-Email-Button {
    bottom: 0.8rem;
  }

  .About-Flex-Parent{
    font-size:1rem;
  }

  p{
    line-height: 1.75rem;
  }
  

}


/* The reason the margins below are almost the same but not quite, is because
if the margins are teh exact same, they really look like they're not lined up to me.
Feels like some sort of optical illusion- so I'm making them slightly off so that they feel right. */




@media (max-width:460px){


  /*
  This(in conjunctino with the variables defined up top)
  makes the page's proportions(background, section sizes) fit better on small devices, due to how those proportions
  work in practice rather than just simply being about sheer resolution size.

  This has an unfortunate side effect of making it look worse when a desktop user resizes their browser window
  to be small, since the navbar won't hug the corner which makes it look nicer,
  but usage in a resized browser window is a minor concern for this site so I'm ignoring it for now.
  */
  
  .Default-Styling{
    font-size: 0.875rem;
    background: -webkit-linear-gradient(white, white 0%, black 0%, #121212, black  calc(var(--default-height)*var(--top-proportion-of-height-BG-end-phone)), white  calc(var(--default-height)*var(--top-proportion-of-height-BG-end-phone)), white);
  }

/*
As-text is here to reassert its background rule over the media querie'd default styling.
The alternative is to separate out what I want from Default-Styling for the navbar button styling
and make the navbars not have default-style reapplied to them(since buttons dont inherit default styling, thus
why they have the style in the first place- to inherit font information).
but for now I would rather just define the font size in fewer locations for less confusion.
*/

  .As-Text{
    background: none;
  }

  .Overall-Grid-Parent{
    grid-template-rows: calc(var(--default-height)*var(--top-proportion-of-height-phone)) calc(var(--default-height)*var(--bottom-proportion-of-height-phone));
  }
  
  .Main-Flex-Parent{
    height: calc(var(--default-height)*var(--top-proportion-of-height-phone));
    font-size:1.1rem;

  }
  .About-Flex-Parent{
    height: calc(var(--default-height)*var(--top-proportion-of-height-phone));
  }

  
  .About-Text-Boundaries{    
    margin: 0rem 0rem 0.25rem 1rem;
  }

  .Portfolio-Parent > .About-Text-Boundaries{
    margin: 1rem 0rem 0rem 1rem;
  }


    /* Currently using About-Text-Boundaries and About-Flex-Parent for both About component and Portfolio component.
    because it is convenient for similar needs and the media queries attached to those.
    Will give it a more generic name if reused for a 3rd time. */


  .Nav-Flex-Parent{
    height: calc(var(--default-height)*var(--bottom-proportion-of-height-phone));
    padding-right:1rem;
  }
    


  .Line-1{
    margin-left: 2.125rem;
  }
  .Line-2{
    margin-left: 2.25rem;
  }
  .Line-3{
    margin-left: 2.375rem;
  }
  .Inline-L{
    max-width: 0.9375rem;
    max-height: 0.9375rem;
  }

  .Svg-Icon-Email-Button {
    bottom: 1rem;
  }

  .Svg-Icon-Email-Button-2{
    bottom: 0.9rem;
  }

  .Large-View{
    display: none;
  }

  .Mobile-View{
    display: inline-block;
    line-height: 1.4rem;
  }

  p{
    line-height: 1.4rem;
  }

}


@media (max-width:370px){

.Nav-Flex-Parent{
  flex-flow: wrap-reverse;
  padding-top: 0rem;
}
}


@media (hover: none) {
  .Hover-Mobile-Adjustment > .Hover-Color:hover {
     color: red;
  }
}